import NavBar from './components/navbar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConnectionsPage from './ConnectionsPage';
import SprinterPage from './SprinterPage';
import './main.css';

const queryClient = new QueryClient()

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <NavBar />
        <Routes>
          <Route path="/" element={<ConnectionsPage />} />
          <Route path="/sprinter" element={<SprinterPage />} />
        </Routes>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
