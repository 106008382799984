import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <div className="bg-white list-none w-full border-l border-green-400 border-l-4">
      <ul className="w-full">
        <li className="inline-block w-1/5 border-t border-l border-black px-4 py-4">
          <Link to="/">Alle heutigen ICE Verbindungen</Link>
        </li>
        <li className="inline-block w-1/5 border-t border-l border-black px-4 py-4">
          <Link to="/sprinter">ICE Sprinter Verbindungen</Link>
        </li>
{/*        <li className="inline-block w-1/5 border-t border-l border-black px-4 py-4">
          <Link to="/sprinter">Hauptstrecken</Link>
        </li>*/}
{/*        <li className="inline-block w-1/5 border-t border-l border-black px-4 py-4">
          <Link to="/sprinter">Hamburg/Berlin-München</Link>
        </li>
        <li className="inline-block w-1/5 border-t border-l border-black px-4 py-4">
          <Link to="/sprinter">Frankfurt-Köln</Link>
        </li>*/}
      </ul>
    </div>
  );
}

export default NavBar;
