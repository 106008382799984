import { useFetchConnectionsSprinter } from './data.js';
import ConnectionsTable from './components/connections-table';

function SprinterPage() {
  const { isPending, data } = useFetchConnectionsSprinter();

  return (
    <ConnectionsTable data={data} is_pending={isPending} />
  );
}

export default SprinterPage;
