import ky from 'ky';
import { useQuery } from '@tanstack/react-query'

export function getConnections() {
  return ky.get('https://api.zuglink.de/connectionstoday')
    .json()
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

export const useFetchConnections = () => {
  return useQuery({
    queryKey: ['fetch-connections'],
    queryFn: () => getConnections(),
  });
};

export function getConnectionsSprinter() {
  return ky.get('https://api.zuglink.de/connectionstoday?is_sprinter=is.true')
    .json()
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
}

export const useFetchConnectionsSprinter = () => {
  return useQuery({
    queryKey: ['fetch-connections-sprinter'],
    queryFn: () => getConnectionsSprinter(),
  });
};
