import { useFetchConnections } from './data.js';
import ConnectionsTable from './components/connections-table'

function ConnectionsPage() {
  const { isPending, data } = useFetchConnections();

  return (
    <ConnectionsTable data={data} is_pending={isPending} />
  );
}

export default ConnectionsPage;
